
body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}


.wrapper {
    background-color: #c4c4c4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}


.infoBadge {
    position: fixed;
    left: 50px;
    top: 50px;
    font-size: 12px;
    max-width: 220px;

    color: rgba(255, 255, 255, 0.75);
}

.infoBadge strong{
    color: white;
    font-size: 11px;
}

.infoBadge img {
    width: 120px;
    margin-bottom: 20px;
}


.logo{
    padding-bottom: 20px;
    width: 400px;
    margin-top: -80px;
    color: white;
}

.logo h2{
    color: white;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
}

.dialog {
    width: 400px;
    background-color: white;
    min-height: 400px;
    border-radius: 5px;
    padding: 40px 40px;
}

.dialog h2 {
    font-weight: 600;
    font-size: 18px;
}
.dialog p {
    color: #646464;
    margin-bottom: 40px;
}

.loginInfo{
    font-size: 12px;
    color: #707070;
}


.ant-input-prefix {
    opacity: 0.5;
}

.ant-input-affix-wrapper-lg input {
    font-size: 15px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-weight: 600;
    font-size: 18px;
}
